.navbar {
    background-color: white;
    box-shadow: 0px 1px 10px #999;
}

.nav-link {

    &.active,
    &:hover {
        color: #6a8aa7 !important;
    }
}

.navbar-brand {
    display: flex;
    align-items: center;
}

.logo-title {
    text-decoration: none;
    font-size: 22px;
    color: black;
    padding-left: 0.5rem;

    &:hover {
        color: black;
    }
}

.site-logo {
    font-size: 48px;
    color: #6b88a7;
}