#page-title {
  position: relative;
  padding: 50px 0;
  background-color: #e5e4e4;
  border-bottom: 1px solid #eee;
  top: -5px;
  p {
    margin-bottom: 0;
  }
}
