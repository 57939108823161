// Override Bootstrap 5 theme colors //
$theme-colors: (
  "primary": #6a88a7,
  "secondary": #333,
);

// Set theme color variables //
$primary: #6a88a7;
$secondary: #333;

// import bootstrap to set changes //
@import "../src/fonts.scss";
@import "../node_modules/bootstrap/scss/bootstrap";
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css");

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  margin-top: 5rem;
}

a {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: $primary;

  &:hover {
    color: $secondary;
  }
}

.btn,
.btn-primary {
  color: white;

  &:hover {
    color: white;
  }
}

h1 {
  font-family: "Raleway", sans-serif;
  margin-bottom: 0;
}

h2 {
  font-family: "Raleway", sans-serif;
  margin-bottom: 0;
}

.text-bold {
  font-weight: 600;
}

.blue-border {
  padding: 0 0 1rem;
  border-bottom: 2px solid $primary;
  margin-bottom: 1rem;
}

.italic-text {
  color: $primary;
  font-size: 1rem;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 5px;
}

.social-icons {
  font-size: 4rem;
  margin: 0 0.5rem 0 0;
}