.homepg-hdr {
  width: 100%;
  height: 450px;
  margin-top: -10px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  background-image: url("../images/urad.jpeg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  p {
    font-size: 1.5rem;
  }

  .home-icon {
    color: #61dafb;
    font-size: 54px;
  }
}