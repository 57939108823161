$secondary: #333;

.breadcrumb {
    margin-bottom: 0;

    .breadcrumb-item {
        &.active {
            a {
                color: $secondary;
                text-decoration: none;
                cursor: auto;
                text-transform: capitalize;
            }
        }
    }
}