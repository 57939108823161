.footer {
    background-color: #58595b;
    color: white;
    text-align: center;

    p {
        margin: 0;
    }

    .home-icon {
        color: #61dafb;
        font-size: 44px;
    }
}